import React, { useEffect, useRef } from 'react'

const Parallax = ({
	className,
	children,
	isOnMobile = true,
	scaleTarget = 0,
	translateTarget = 10,
}) => {

	const containerEl = useRef(null)
	const childrenContainerEl = useRef(null)

	useEffect(() => {

		let rafId = null

		const tick = () => {

			const containerElRect = containerEl.current.getBoundingClientRect()
			const visibilityRatio = (containerElRect.top + containerElRect.height) / (containerElRect.height + window.innerHeight)
			const visibilityRatioReversed = 1 - (containerElRect.top + containerElRect.height) / (containerElRect.height + window.innerHeight)

			if (visibilityRatio <= 1 && visibilityRatio >= 0) {
				childrenContainerEl.current.style.transform = `scale(${1 + scaleTarget * visibilityRatioReversed})`
				containerEl.current.style.transform = `translate3d(0, ${translateTarget * (visibilityRatio - 0.5)}%, 0)`
			}

			rafId = requestAnimationFrame(tick)
		}

		if (window.innerWidth < 1025 && isOnMobile === false) {
			return
		}

		tick()

		return () => {
			window.cancelAnimationFrame(rafId)
		}
	}, [])

	return (
		<div
			className={`Parallax ${className}`}
			ref={containerEl}
		>
			<div
				className="Parallax__ChildrenContainer"
				ref={childrenContainerEl}
			>
				{children}
			</div>
		</div>
	)
}

export default Parallax