import React, { useRef, useEffect, useState } from 'react'
import Player from '@vimeo/player'
import ImageLazyLoad from '../components/ImageLazyLoad'

const VideoHover = ({
	className,
	alt,
	src,
	srcSet,
	vimeoId
}) => {

	const iframeContainerEl = useRef(null)
	const player = useRef(null)
	const [isVideoVisible, setIsVideoVisible] = useState(false)
	const isPlaying = useRef(false)

	useEffect(() => {
		if (vimeoId === null || vimeoId === '' || localStorage.getItem('is-touch') === 'true') {
			return
		}

		const options = {
			id: vimeoId,
			responsive: true,
			autoplay: false,
			controls: false,
			muted: true,
			loop: true
		}

		player.current = new Player(iframeContainerEl.current, options)
		player.current.on('playing', () => {
			isPlaying.current = true
		})
		player.current.on('pause', () => {
			isPlaying.current = false
		})

		return () => {
			player.current.destroy()
		}
	}, [])

	const handleMouseEnter = () => {
		if (player.current === null || localStorage.getItem('is-touch') === 'true') {
			return
		}
		player.current.play()
		setIsVideoVisible(true)
	}

	const handleMouseLeave = () => {
		if (player.current === null || localStorage.getItem('is-touch') === 'true') {
			return
		}
		if (isPlaying.current === true) {
			player.current.pause()
			player.current.setCurrentTime(0)
		}
		setIsVideoVisible(false)
	}

	return (
		<div
			className={`VideoHover ${className}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="VideoHover__Container">
				<ImageLazyLoad
					className="VideoHover__Image"
					alt={alt}
					src={src}
					srcSet={srcSet}
				/>
				<div
					className={`VideoHover__IframeContainer ${isVideoVisible ? 'isVisible' : ''}`}
					ref={iframeContainerEl}
				></div>
			</div>
		</div>
	)
}

export default VideoHover