import React, { useRef, useState, useEffect } from 'react'

const ImageLazyLoad = ({ className, alt, src, srcSet }) => {

	const imgEl = useRef(null)
	const [imageLoaded, setImageLoaded] = useState(false)

	useEffect(() => {
		// If image is already loaded
		if (imgEl.current.complete === true) {
			setImageLoaded(true)
		}
		// Add event listener
		imgEl.current.addEventListener('load', () => {
			setImageLoaded(true)
		})
	})

	return (
		<img
			className={`ImageLazyLoad ${className} ${imageLoaded ? 'loaded' : ''}`}
			alt={alt}
			src={src}
			srcSet={srcSet}
			ref={imgEl}
		/>
	)
}

export default ImageLazyLoad