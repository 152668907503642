import React, { useEffect, useContext, useState } from 'react'
import { graphql } from 'gatsby'
import ratio from '../helpers/ratio'
import Contact from '../components/Contact'
import SplitText from '../components/SplitText'
import VimeoPlayer from '../components/VimeoPlayer'
import Parallax from '../components/Parallax'
import VideoHover from '../components/VideoHover'
import { GlobalEventBus } from '../helpers/eventBus'
import { ContextLoader } from '../context'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import Seo from '../components/Seo'

const templatePageCategory = [
	{
		top: 17,
		left: 16,
		right: 0,
		bottom: 0,
		translateTarget: 40
	},
	{
		top: 24,
		left: 28.5,
		right: 0,
		bottom: 0,
		translateTarget: 20
	},
	{
		top: 10,
		left: 39,
		right: 62.7,
		bottom: 0,
		translateTarget: 15
	},
	{
		top: 17,
		left: 4,
		right: 0,
		bottom: 0,
		translateTarget: 10
	},
	{
		top: 10,
		left: 50.9,
		right: 0,
		bottom: 0,
		translateTarget: 40
	},
	{
		top: 10,
		left: 62.8,
		right: 0,
		bottom: 0,
		translateTarget: 10
	}
]

const Category = ({ data }) => {
	const pageData = data.prismicCategory.data

	/**
 	*	Detect elements on screen
	*/
	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	/**
 	*	Color
	*/
	useEffect(() => {
		GlobalEventBus.emit('navColorChange', { newColor: 'black' })
	}, [])


	/**
 	*	Player and project click
	*/
	const [selectedProject, setSelectedProject] = useState(null)
	const handleClickOnProject = (project) => {
		if (project.vimeo_id.length === 0) {
			return
		}
		setSelectedProject(project)
	}

	const handlePlayerClose = () => {
		setSelectedProject(null)
	}

	return (
		<div className="Category">
			<Seo
				title={pageData.name.text}
				description='Independant Production Company Focused on Delivering Inspiring and Well- Crafted Content Across Advertising, Branded Content, Short Fiction and Documentary'
			/>
			<div className="Category__Projects">
				{pageData.projects.map((project, index) => {
					const moduloIndex = index % 6
					return (
						<div
						className="Category__ProjectWrapper"
							key={`category-project-${index}`}
							style={{
								marginTop: ratio(templatePageCategory[moduloIndex].top) + 'vw',
								marginLeft: ratio(templatePageCategory[moduloIndex].left) + 'vw',
								marginRight: ratio(templatePageCategory[moduloIndex].right) + 'vw',
								marginBottom: ratio(templatePageCategory[moduloIndex].bottom) + 'vw',
							}}
						>
							<Parallax
								className="Category__ProjectParallax"
								translateTarget={templatePageCategory[moduloIndex].translateTarget}
								isOnMobile={false}
							>
								<div
									className="Category__Project"
									onClick={() => handleClickOnProject(project.project.document.data)}
								>
									<VideoHover
										className="Category__ProjectImageWrapper"
										alt={project.project.document.data.image_category.alt}
										src={project.project.document.data.image_category.fluid.src}
										srcSet={project.project.document.data.image_category.fluid.srcSet}
										vimeoId={project.project.document.data.video_hover_vimeo_id.text}
									/>
									<h5 className="Category__ProjectClient" data-is-on-screen>
										{project.project.document.data.client.text}
									</h5>
									<h6 className="Category__ProjectTitle" data-is-on-screen>
										<SplitText hasMask>
											{project.project.document.data.title.text}
										</SplitText>
									</h6>
								</div>
							</Parallax>
						</div>
					)
				})}
			</div>
			<Contact data={data.prismicContact.data}/>
			<VimeoPlayer
				project={selectedProject}
				onClose={handlePlayerClose}
			/>
		</div>
	)
}

export const CategoryQuery = graphql`
  query categoryByUid($uid: String!) {
		prismicCategory(uid: {eq: $uid}) {
			uid
			data {
				name {
					text
				}
				projects {
					project {
						document {
							... on PrismicProject {
								id
								data {
									client {
										text
									}
									title {
										text
									}
									image {
										alt
										fluid {
											src
											srcSet
										}
									}
									image_category {
										alt
										fluid {
											src
											srcSet
										}
									}
									vimeo_id {
										text
									}
									video_hover_vimeo_id {
										text
									}
									production_company {
										text
									}
									director {
										text
									}
								}
							}
						}
					}
				}
			}
		}
		prismicNav {
      ...Nav
    }
		prismicContact {
      ...Contact
    }
  }
`

export default Category;